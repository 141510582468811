.welcome{
  height: 460px;
  width: 100%;
  background-color: var(--main-color);
  display:flex;
  justify-content: space-between;
  position:relative;
}
.welcome *{
  color: var(--font-on-main);
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
}
.welcome>img{
  height:100%;
  position:absolute;
  right:0
}
.welcome-text{
  width:65%;
  z-index: 2;
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 5% 8%;
}
.welcome-title{
  font-size: 2rem;
  font-family: fihogar-bold
}
.welcome-subtitle{
  font-size: 1.5rem;
  font-family: fihogar-bold
}



.cards-container{
  padding: 6% 5%;
  display:flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.card{
  width: 25vw;
  height: 14vw;
  border-radius: 20px;
  box-shadow: 0 2px 8px 0 rgba(80, 80, 80, 0.78);
  background-color: #f5f6f8;
  display:flex;
  justify-content: space-between;
  padding: 30px 55px;
  transition: 0.3s;
  margin-top: 2%
}
.card:hover{
  transform: scale(1.01);
}
.card * {
  color: var(--font);
  margin:0
}
.card-icon{
  display:flex;
  justify-content: center;
  align-items: center;
  width: 20%
}
.card-icon>*{
  font-size: 2rem;
  width: 2em;
  fill: white
}
.card-text{
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}
.card-title{
  font-size: 1.5rem;
  font-family: fihogar-bold
}



.home-carousel-container{
  height: 650px;
  width: 100%;
  background-color: var(--light-background);
  display:flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #e4e0fe;
}
.home-carousel-container>div{
  width: 90%
}
.carousel-slider{
  box-shadow: 0 2px 8px 0 rgba(99, 99, 99, 0.5);
  border-radius: 20px;
  z-index: 1
}
.carousel-slide{
  width: 100%;
  height: 510px;
  background-color: #ffffff;
  display:flex;
  border-radius: 20px;
  overflow: hidden;
}
.carousel-slide>*{
  width:50% !important
}
.carousel-slide>img{
  object-fit: cover;
  height:100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.carousel-txt{
  padding: 6% 6% 4% 6%;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.carousel-txt *{
  margin:0;
  text-align: left;
  line-height: 1.5;
  color: var(--font2);
  margin-top: 1.5em
}
.carousel-txt>*{
  margin:0;
}
.carousel-txt>div>p:first-child{
  margin-top: 0
}
.carousel-txt button{
  width: 15em;
  height: 2.5rem;
  border-radius: 24px;
  background-color: #ec008c;
  font-size: 1rem;
  color: var(--font-on-main);
  text-align: center;
  cursor: pointer;
  border: none
}
.slider-wrapper{
  border-radius: 24px
}
.slide, .slide.selected{
  background: none !important
}
.control-dots{
  padding:0;
}
.control-dots > *{
  box-shadow: none !important;
  width: 16px !important;
  height: 16px !important;
}
.home-carousel-container .control-dots .dot{
  background: var(--light-background) !important;
  border: #999999 solid 1px !important;
  opacity: 1 !important
}
.home-carousel-container .control-dots .dot.selected{
  background: var(--main-color) !important;
  border: var(--main-color) solid 1px !important;
}


.partner-container{
  height: 650px;
  background-color: white;
  display:flex;
  justify-content: center;
  align-items: center;
}

.api-terms{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-color);
  font-size: 0.8em
}
.api-terms>p{
  color: white
}
.api-terms>p>a{
  color: white
}
/* .api-terms>p>a:hover{
  text-decoration: none;
} */
.api-terms>p>a:focus{
  color: white
}



@media only screen and (max-width: 1024px) {
  .welcome{
    height: 25vh;
  }
  .welcome-text{
    font-size: 0.66rem;
  }
  .welcome * {
    font-size: 0.8rem;
  }
  .welcome-title{
    font-size: 1.2rem
  }
  .welcome-subtitle{
    font-size: 1rem
  }

  .cards-container{
    padding: 8% 5%
  }
  .cards-container>a{
    width:40vw;
    margin-bottom: 30px
  }
  .card{
    width: 100%;
    height: 15vh;
    margin:0;
    padding: 5% 10%
  }
  .card-title{
    font-family: fihogar-bold;
    font-size: 1.25rem
  }

  .home-carousel-container{
    height: 45vh
  }
  .carousel-slide{
    height:35vh
  }
  .carousel-txt{
    padding: 0 5%;
    height: 80%;
    margin-top: 5%;
    overflow: scroll;
    justify-content: flex-start;
  }
  .carousel-txt p{
    text-align: justify;
  }

  .partner-container{
    height: 45vh
  }
}

@media only screen
and (min-device-width : 768px)
and (max-width : 1024px)
and (orientation : landscape) {
  .welcome{
    height: 40vh;
  }

  .home-carousel-container{
    height: 80vh
  }
  .carousel-slide{
    height: 60vh
  }

  .partner-container{
    height: 80vh
  }
}

@media only screen and (max-width: 450px) {
  .welcome{
    height: 80vh;
  }
  .welcome-text{
    padding: 5%;
    width: 100%;
    height:50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .welcome-text>p{
    font-size: 0.66rem
  }
  .welcome-text .welcome-title{
    font-size: 1rem
  }
  .welcome-text .welcome-subtitle{
    font-size: 0.8rem
  }
  .welcome>img{
    width:100%;
    height: auto;
    bottom:0
  }

  .cards-container{
    padding: 8% 5%;
    flex-direction: column;
  }
  .cards-container>a{
    width:100%
  }
  .card{
    width: 100%;
    height: 20vh;
    margin-bottom: 1em;
    padding: 10% 12%
  }
  .card-text>p{
    font-size: 0.66rem
  }
  .card-text .card-title{
    font-size: 1rem;
    font-family: fihogar-bold
  }

  .home-carousel-container{
    height: 80vh
  }
  .carousel-slide{
    height:70vh;
    flex-direction: column;
  }
  .carousel-slide>img{
    width: 100% !important;
    height: 40%;
    border-radius: 0
  }
  .carousel .control-dots{
    bottom: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .carousel-txt{
    padding: 0 10% 8% 10%;
    margin-top: 18%;
    width: 100% !important;
    justify-content: space-between;
    height: 40%;
  }
  .carousel-txt p{
    font-size: 0.66rem;
    text-align: justify;
  }
  .carousel button{
    height: 2rem;
    font-size: 0.66rem
  }

  .partner-container{
    min-height: auto;
    padding: 10% 5%;
    height: auto
  }
}

@media only screen
and (max-width : 812px) and
(orientation : landscape) {
  .welcome{
    height: 50vh
  }
  .welcome-text{
    padding: 5%;
    width: 60%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .welcome-text>p{
    font-size: 0.5rem
  }
  .welcome-text .welcome-title{
    font-size: 1rem
  }
  .welcome-text .welcome-subtitle{
    font-size: 0.75rem
  }
  .welcome>img{
    width:auto;
    height: 100%;
    bottom:0
  }
  .cards-container>a{
    width:40%
  }
  .card{
    width: 100%;
    height: 30vh;
    margin-bottom: 1em;
    padding: 10% 12%
  }
  .card-text>p{
    font-size: 0.66rem
  }
  .card-text .card-title{
    font-size: 0.75rem;
    font-family: fihogar-bold
  }
  .home-carousel-container{
    height: 100vh
  }
  .carousel-slide{
    height:70vh;
  }
  .carousel-slide>img{
    height: 100%;
    border-radius: 0
  }
  .carousel .control-dots{
    bottom: 2%;
  }
  .carousel-txt{
    padding: 0 5%;
    justify-content: space-between;
    height:80%
  }
  .carousel-txt p{
    font-size: 0.66rem;
    text-align: justify;
  }
  .carousel button{
    height: 1.5rem;
    font-size: 0.66rem
  }

  .partner-container{
    height: 80vh;
  }
}
